import React from 'react';

import Inner from './inner';

import './button.css';

export default function ButtonLink(props) {
  const {
    href,
    target,
    label,
    children,
    primary,
    secondary,
    dark,
    ref,
    style,
    className,
    ...other
  } = props;

  const innerProps = {
    className,
    primary,
    secondary,
    dark,
    style,
    ref,
  };

  return (
    <a href={href} target={target} aria-label={label || children} {...other}>
      <Inner {...innerProps}>{children}</Inner>
    </a>
  );
}
